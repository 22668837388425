// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile =  {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:a77fb018-3624-44c8-9f6f-21120295c1c7",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_KfkVD9yIX",
    "aws_user_pools_web_client_id": "26msq3r96j8qu8nc0n8o0hgrp9",
    "aws_user_files_s3_bucket": "taggedimages",
    "aws_user_files_s3_bucket_region": "us-east-1",
    "aws_content_delivery_bucket": "joshstir.com",
    "aws_content_delivery_bucket_region": "us-east-1",
    "aws_content_delivery_url": "https://d2tvzj25lehue9.cloudfront.net",
    "aws_appsync_graphqlEndpoint": "https://sqe4757yxbahjc5tlaz37zemsy.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS"
};


export default awsmobile;
