import React, { useEffect, useState } from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import CardActionArea from "@material-ui/core/CardActionArea";
import Typography from "@material-ui/core/Typography";
import Chip from '@material-ui/core/Chip';
import "./Imagecard.css";
import { Storage } from "aws-amplify";


const Imagecard = ({ image }) => {
    const [poster, setposter] = useState(null)
    const [imageName, setImageName] = useState(image.Image)
  //let poster =
  //  image.Poster === "N/A" ? DEFAULT_PLACEHOLDER_IMAGE : image.Poster;
  useEffect(() => {
    Storage.get(image.Image, { level: "private" })
      .then(result => {
        setposter(result.toString());
      })
      .catch(err => {
        console.log(err);
      });

      const pos = imageName.indexOf("/");
      setImageName(imageName.substring(pos+1));
  }, []);

  return (
    <Card className="movie" raised={true}>
      <CardActionArea>
        <CardMedia className="media" image={poster} title={image.Image} />
        <CardContent>
          <Typography gutterBottom variant="h5" component="h2">
            {imageName}
          </Typography>
          {
          image.Tags.map((tag, index) => (
            <Chip label={tag} color="secondary" style={{margin:2}} />            
          ))}          
        </CardContent>
      </CardActionArea>
    </Card>
    /*
        <div className="movie">
            <h2>{movie.Title}</h2>
            <div>
                <img
                    width="200"
                    alt={`The movie titled: ${movie.Title}`}
                    src={poster}
                />
            </div>
            <p>({movie.Year})</p>
        </div>*/
  );
};

export default Imagecard;
