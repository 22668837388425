import React, { useEffect, useState } from "react";
import "./App.css";
import CircularProgress from "@material-ui/core/CircularProgress";
import { withAuthenticator } from "aws-amplify-react"; // or 'aws-amplify-react-native';
// Get the aws resources configuration parameters
import aws_exports from "./aws-exports"; // if you are using Amplify CLI
import Header from "./components/Header";
import Amplify, { API, graphqlOperation } from "aws-amplify";
import Imagecard from "./components/Imagecard";
import { Auth } from "aws-amplify";
import * as queries from "./graphql/queries";
import ReconnectingWebSocket from 'reconnecting-websocket';

Amplify.configure(aws_exports);
const rws = new ReconnectingWebSocket('wss://85du566oz1.execute-api.us-east-1.amazonaws.com/Test');


const App = () => {
  const [loading, setLoading] = useState(true);
  const [images, setImages] = useState([]);
  const [errorMessage, setErrorMessage] = useState(null);
  const [searchValue, setsearchValue] = useState("");
  const [, setMessage] = useState("");  
  const [temp, setTemp] = useState([]);
  const [, setOpen] = useState(false);

  useEffect(() => {
    rws.addEventListener('open', handleOpen)
    rws.addEventListener('message', handleData);

    async function getAllImages() {
      try {
        const images = await API.graphql(graphqlOperation(queries.allImages));
        setImages(images.data.AllImages.images);
        setLoading(false);
        console.log(images);
      } catch (error) {
        setLoading(false);
        setErrorMessage(error.errorMessage);
        setImages([]);
      }
    }
    getAllImages();
  }, []);

  const search = async searchValue => {
    setLoading(true);
    setErrorMessage(null);
    setsearchValue(searchValue);

    if (searchValue) {
      const images = await API.graphql(
        graphqlOperation(queries.images, { Tags: searchValue })
      );
      setImages(images.data.Images.images);
    } else {
      const images = await API.graphql(graphqlOperation(queries.allImages));
      setImages(images.data.AllImages.images);
    }
    setLoading(false);
  };

  const handleData = e => {
    console.log(e.data);
    var obj = JSON.parse(e.data)
    if(!obj.message){
      setOpen(true);
      temp.push(`Image ${obj.ImageName} added with the following tags: ${obj.Tags}`);
      setTemp(temp);      
      setMessage(e.data);
    }        
  };

  const handleOpen = async () => {
    console.log("open");
  
    const info = await Auth.currentCredentials();

    sendMessage(`{"action":"register", "message":"${info.identityId}"}`);
  };

  const sendMessage = message => {
    //            this.ws.send(`{"action":"register", "message":"${id}"}`);
    //ws.send(message);
    console.log(message);
    //refWebSocket.sendMessage(message);
    //sock.ws.send(message);
    rws.send(message);
  };

  
  const showMessagDialog = () => {

  }

  const clearMessages = () => {
    setTemp([]);
  }
  return (
    <div className="app">
      <Header search={search} clearMessages={clearMessages} messages={temp} />
      {searchValue ? <div>Filter: {searchValue}</div> : <div />}
      <div className="movies">
        {loading && !errorMessage ? (
          <div className="loader">
            <CircularProgress />
          </div>
        ) : errorMessage ? (
          <div className="errorMessage">{errorMessage}</div>
        ) : (
          images.map((image, index) => (
            <Imagecard key={`${index}-${image.Image}`} image={image} />
          ))
        )}
      </div>
    </div>
  );
};

export default withAuthenticator(App);
