import React, {  } from "react";
import "filepond/dist/filepond.min.css";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import Button from "@material-ui/core/Button";
import { DialogContent, DialogActions } from "@material-ui/core";

class InfoDialog extends React.Component {
  componentDidMount() {}

  render() {
    const { classes, onClose, ...other } = this.props;
    debugger;
    return (
      <Dialog
        onClose={this.handleClose}
        aria-labelledby="simple-dialog-title"
        fullWidth={true}
        maxWidth="sm"
        {...other}
      >
        <DialogTitle id="dialog-title">Image Info</DialogTitle>
        <DialogContent>
          <ul>
            {this.props.messages.map((message, index) => (
               <li key={`${index}-${message}`}>{message}</li> 
            ))}
            </ul>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary" autoFocus>
            Done
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default InfoDialog;
