// tslint:disable
// this is an auto generated file. This will be overwritten

export const images = `query Images($Tags: String!) {
  Images(Tags: $Tags) {
    images {
      Tags
      User
      Image
      ID
    }
  }
}
`;
export const allImages = `query AllImages {
  AllImages {
    images {
      Tags
      User
      Image
      ID
    }
  }
}
`;
