import React, {  } from "react";
import { FilePond } from "react-filepond";
import "filepond/dist/filepond.min.css";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import Button from "@material-ui/core/Button";
import { DialogContent, DialogActions } from "@material-ui/core";
import { Storage } from "aws-amplify";

class Upload extends React.Component {
  componentDidMount() {}
  handleInit() {
    this.pond._pond.setOptions({
      server: {
        process: function(
          fieldName,
          file,
          metadata,
          load,
          error        ) {
          Storage.put(`images/${file.name}`, file, {
            level: "private",
            contentType: "text/plain"
            
          })
            .then(result => {
              console.log(result);
              load(result.key);
            })
            .catch(err => {
              console.log(err);
              error(err);
            });
        }
      }
    });
  }

  render() {
    const { classes, onClose, selectedValue, ...other } = this.props;
    return (
      <Dialog
        onClose={this.handleClose}
        aria-labelledby="simple-dialog-title"
        fullWidth={true}
        maxWidth="sm"
        {...other}
      >
        <DialogTitle id="dialog-title">Upload Images</DialogTitle>
        <DialogContent>
          <FilePond
            allowMultiple={true}
            ref={ref => (this.pond = ref)}
            oninit={() => this.handleInit()}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary" autoFocus>
            Done
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default Upload;
